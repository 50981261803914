import React from "react"
import PropTypes from "prop-types"

import Columns from "./columns"
import Column from "./column"
import Box from "./box"

const TaskSidebar = ({ sidebar, main }) => {
  return (
    <Columns
      collapse={[true, true, false]}
      reverse={[false, false, true]}
      space={[6, 6, 12]}
    >
      <Column width={["100%", "100%", "5/12"]}>
        <Box sx={{ position: ["static", null, "sticky"], top: 12 }}>
          {sidebar}
        </Box>
      </Column>
      <Column width={["100%", "100%", "7/12"]}>{main}</Column>
    </Columns>
  )
}

TaskSidebar.propTypes = {
  sidebar: PropTypes.node,
  main: PropTypes.node,
}

export default TaskSidebar
